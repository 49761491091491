// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comfortclick-js": () => import("./../../../src/pages/comfortclick.js" /* webpackChunkName: "component---src-pages-comfortclick-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jung-js": () => import("./../../../src/pages/jung.js" /* webpackChunkName: "component---src-pages-jung-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-partneri-js": () => import("./../../../src/pages/partneri.js" /* webpackChunkName: "component---src-pages-partneri-js" */),
  "component---src-pages-projekti-js": () => import("./../../../src/pages/projekti.js" /* webpackChunkName: "component---src-pages-projekti-js" */),
  "component---src-pages-thinknx-js": () => import("./../../../src/pages/thinknx.js" /* webpackChunkName: "component---src-pages-thinknx-js" */),
  "component---src-pages-usluge-js": () => import("./../../../src/pages/usluge.js" /* webpackChunkName: "component---src-pages-usluge-js" */),
  "component---src-pages-wago-js": () => import("./../../../src/pages/wago.js" /* webpackChunkName: "component---src-pages-wago-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-projekat-js": () => import("./../../../src/templates/projekat.js" /* webpackChunkName: "component---src-templates-projekat-js" */)
}

